/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, props } from '@ngrx/store';

import {
  BoxDetails,
  boxesByDate,
  Box,
  Part,
  PartResponseDto,
  TagB,
  OpenBoxResponseDto,
} from 'src/app/common/models';

/** Loading boxes */
export const loadingBoxes = createAction(
  '[Boxes] Loading boxes',
  (filter: string = '', refreshEvent?: any) => ({ filter, refreshEvent })
);

export const loadingBoxesSuccess = createAction(
  '[Boxes] Boxes loading success',
  props<{ boxesByDates: boxesByDate[] }>()
);

export const loadingBoxesError = createAction(
  '[Boxes] Boxes loading error',
  props<{ error: any }>()
);

/** Open box */
export const openBox = createAction(
  '[Boxes] Open box request',
  props<{ tagB: TagB }>()
);

export const openBoxSuccess = createAction(
  '[Boxes] Open box success',
  props<{ boxId: number; boxesByDates: boxesByDate[] }>()
);

export const openBoxError = createAction(
  '[Boxes] Open box error',
  props<{ error: any }>()
);

/** Reopen box */
export const reopenBox = createAction(
  '[Boxes] Reopen box request',
  props<{ box: Box }>()
);

export const reopenBoxSuccess = createAction(
  '[Boxes] Reopen box success',
  props<{ box: any }>()
);

export const reopenBoxError = createAction(
  '[Boxes] Reopen box error',
  props<{ error: any }>()
);

/** Suspend box */
export const suspendBox = createAction(
  '[Boxes] Suspend box request',
  props<{ box: Box }>()
);

export const suspendBoxSuccess = createAction(
  '[Boxes] Suspend box success',
  props<{ box: Box }>()
);

export const suspendBoxError = createAction(
  '[Boxes] Suspend box error',
  props<{ error: any }>()
);

/** Loading box details */
export const loadingBoxDetails = createAction(
  '[Boxes] Loading box details',
  props<{ boxId: number }>()
);

export const loadingBoxDetailsSuccess = createAction(
  '[Boxes] Loading box details success',
  props<{ boxDetails: BoxDetails }>()
);

export const loadingBoxDetailsError = createAction(
  '[Boxes] Loading box details error',
  props<{ error: any }>()
);

/** Boxing a part */
export const boxPart = createAction(
  '[Boxes] Place a part inside box request',
  props<{ part: Part; box: Box; ficheBarcode: string }>()
);

export const boxPartSuccess = createAction(
  '[Boxes] Place a part inside box success',
  props<{ partResponseDto: PartResponseDto }>()
);

export const boxPartError = createAction(
  '[Boxes] Place a part inside box error',
  props<{ error: any }>()
);

/** Unboxing a part */
export const unboxPart = createAction(
  '[Boxes] Unbox part request',
  props<{ part: Part; box: Box }>()
);

export const unboxPartSuccess = createAction(
  '[Boxes] Unbox part success',
  props<{ partResponseDto: PartResponseDto }>()
);

export const unboxPartError = createAction(
  '[Boxes] Unbox part error',
  props<{ error: any }>()
);

/** Unboxing a part */
export const closeBox = createAction(
  '[Boxes] Close box request',
  props<{ box: Box }>()
);

export const closeBoxSuccess = createAction('[Boxes] Close box success');

export const closeBoxError = createAction(
  '[Boxes] Close box error',
  props<{ error: any }>()
);
