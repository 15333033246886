import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';

import { Box, Expedition } from 'src/app/common/models';

const API_URL: string = environment.applicationApiUrl;

@Injectable({
  providedIn: 'root',
})
export class ExpeditionsService {
  pipe = new DatePipe(navigator.language);

  constructor(private httpClient: HttpClient) {}

  getExpeditions() {
    const currentDate = new Date();

    const yesterday = new Date(currentDate);
    yesterday.setDate(yesterday.getDate() - 1);

    const tomorrow = new Date(currentDate);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const sYesterday: string = this.pipe.transform(yesterday, 'yyyyMMdd');
    const sTomorrow: string = this.pipe.transform(tomorrow, 'yyyyMMdd');

    return this.httpClient
      .get<Expedition[]>(`${API_URL}/expedition/${sYesterday}/${sTomorrow}`)
      .toPromise();
  }

  getExpeditionBoxes(expeditionId: number): Promise<Box[]> {
    return this.httpClient
      .get<Box[]>(`${API_URL}/expedition/${expeditionId}`)
      .toPromise();
  }

  endExpedition(expedition: Expedition) {
    const payload = {
      dateTime: expedition.dateTime,
      transportType: expedition.transportType,
    };

    return this.httpClient
      .post(`${API_URL}/expedition/end`, payload)
      .toPromise();
  }

  abortExpedition(expedition: Expedition) {
    const payload = {
      dateTime: expedition.dateTime,
      transportType: expedition.transportType,
    };

    return this.httpClient
      .post(`${API_URL}/expedition/abort`, payload)
      .toPromise();
  }

  dispatchBox(box: Box, expedition: Expedition) {
    const payload = {
      dateTime: expedition.dateTime,
      transportType: expedition.transportType,
      boxIdentifier: {
        sequenceType: box.sequenceType,
        sequenceDate: box.sequenceDate,
        supplier: box.supplier,
        refNumber: box.refNumber,
      },
    };

    return this.httpClient
      .post(`${API_URL}/expedition/box/dispatch`, payload)
      .toPromise();
  }

  removeBox(box: Box, expedition: Expedition) {
    const payload = {
      dateTime: expedition.dateTime,
      transportType: expedition.transportType,
      boxIdentifier: {
        sequenceType: box.sequenceType,
        sequenceDate: box.sequenceDate,
        supplier: box.supplier,
        refNumber: box.refNumber,
      },
    };

    return this.httpClient
      .post(`${API_URL}/expedition/box/remove`, payload)
      .toPromise();
  }
}
