import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import {
  BoxMetadata,
  ApiVersionMetadata,
  PartMetadata,
  ErrorMetadata,
  ColorMetadata,
  ExpeditionMetadata,
  Configuration,
} from '../../common/models';

const API_URL: string = environment.applicationApiUrl;

@Injectable({
  providedIn: 'root',
})
export class MetadataService {
  public boxMetadata: BoxMetadata;
  public partMetadata: PartMetadata;
  public errorMetadata: ErrorMetadata;
  public colorMetadata: ColorMetadata;
  public expeditionMetadata: ExpeditionMetadata;
  public apiVersionMetadata: ApiVersionMetadata;
  public configurationMetadata: Configuration[];

  constructor(private httpClient: HttpClient) {}

  getBoxMetadata() {
    return this.boxMetadata;
  }

  getPartMetadata() {
    return this.partMetadata;
  }

  getErrorMetadata() {
    return this.errorMetadata;
  }

  getColorMetadata() {
    return this.colorMetadata;
  }

  getExpeditionMetadata() {
    return this.expeditionMetadata;
  }

  getApiVersion() {
    return this.apiVersionMetadata;
  }

  getConfigurationMetadata() {
    return this.configurationMetadata;
  }

  async load(): Promise<void> {
    await this.getApiBoxStateMetadata()
      .then((data: BoxMetadata) => {
        this.boxMetadata = data;
      })
      .catch(error => {
        console.log(error);
      });

    await this.getApiPartStateMetadata()
      .then((data: PartMetadata) => {
        this.partMetadata = data;
      })
      .catch(error => {
        console.log(error);
      });

    await this.getApiErrorStateMetadata()
      .then((data: ErrorMetadata) => {
        this.errorMetadata = data;
      })
      .catch(error => {
        console.log(error);
      });

    await this.getApiColorDictionaryMetadata()
      .then((data: ColorMetadata) => {
        this.colorMetadata = data;
      })
      .catch(error => {
        console.log(error);
      });

    await this.getApiExpeditionStateMetadata()
      .then((data: ExpeditionMetadata) => {
        this.expeditionMetadata = data;
      })
      .catch(error => {
        console.log(error);
      });

    this.loadConfigurationMetadata();
  }

  /**
   * Load the configuration metadata
   */
  async loadConfigurationMetadata(): Promise<void> {
    await this.getApiConfigurationMetadata()
      .then((data: Configuration[]) => {
        this.configurationMetadata = data;
      })
      .catch(error => {
        console.log(error);
      });
  }

  checkFicheReadConfiguration(): boolean {
    return (
      this.configurationMetadata.find(
        configuration => configuration.name === 'ficheRead'
      ).value === 'true'
    );
  }

  /**
   * Get the box state metadata from API
   * @returns boxMetadata on promise
   */
  getApiBoxStateMetadata(): Promise<BoxMetadata> {
    return this.httpClient
      .get<BoxMetadata>(`${API_URL}/metadata/box`)
      .toPromise();
  }

  /**
   * Get the part state metadata from API
   * @returns partMetadata on promise
   */
  getApiPartStateMetadata(): Promise<PartMetadata> {
    return this.httpClient
      .get<PartMetadata>(`${API_URL}/metadata/part`)
      .toPromise();
  }

  /**
   * Get the error state metadata from API
   * @returns errorMetadata on promise
   */
  getApiErrorStateMetadata(): Promise<ErrorMetadata> {
    return this.httpClient
      .get<ErrorMetadata>(`${API_URL}/metadata/errors`)
      .toPromise();
  }

  /**
   * Get the color dictionary metadata from API
   * @returns colorMetadata on promise
   */
  getApiColorDictionaryMetadata(): Promise<ColorMetadata> {
    return this.httpClient
      .get<ColorMetadata>(`${API_URL}/metadata/color`)
      .toPromise();
  }

  /**
   * Get the expedition state metadata from API
   * @returns expeditionMetadata on promise
   */
  getApiExpeditionStateMetadata(): Promise<ExpeditionMetadata> {
    return this.httpClient
      .get<ExpeditionMetadata>(`${API_URL}/metadata/expedition`)
      .toPromise();
  }

  /**
   * Get the version metadata from API
   * @returns apiVersionMetadata on promise
   */
  getApiVersionMetadata(): Promise<ApiVersionMetadata> {
    return this.httpClient
      .get<ApiVersionMetadata>(`${API_URL}/metadata/version`)
      .toPromise();
  }

  /**
   * Get the configuration metadata
   * @returns Global configurations on promise
   */
  getApiConfigurationMetadata(): Promise<Configuration[]> {
    return this.httpClient
      .get<Configuration[]>(`${API_URL}/metadata/configuration`)
      .toPromise();
  }
}
