import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../../services/index';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const isLogged = this.authService.isLogged();

    if (isLogged) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }

  canAccess(canAccessRoles?: string[], userRoles?: string[]): boolean {
    if (!userRoles) userRoles = this.authService.loadAuthData().roles;

    if (canAccessRoles.includes('all') || userRoles.includes('admin'))
      return true;

    return userRoles.some(userRole => canAccessRoles.includes(userRole));
  }
}
