import { MetadataService } from 'src/app/services';
import { AlertService } from './../../services/alert/alert.service';
import { BoxDetails, boxesByDate } from './../../common/models/box.types';
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import * as BoxesActions from './boxes.actions';

import { LoadingService, BoxesService, ToastService } from '../../services';

@Injectable()
export class BoxesEffects {
  public pipe = new DatePipe(navigator.language);

  constructor(
    private actions$: Actions,
    private loadingService: LoadingService,
    private boxesService: BoxesService,
    private toastService: ToastService,
    private translate: TranslateService,
    private alertService: AlertService,
    private metadataService: MetadataService,
    private router: Router
  ) {}

  loadingBoxes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BoxesActions.loadingBoxes),
      exhaustMap(async action => {
        try {
          const boxesByDates = await this.boxesService.synchroBoxes(
            action.filter
          );

          if (action.refreshEvent) action.refreshEvent.target.complete();

          return BoxesActions.loadingBoxesSuccess({ boxesByDates });
        } catch (error) {
          return BoxesActions.loadingBoxesError({ error });
        }
      })
    );
  });

  loadingBoxesError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(BoxesActions.loadingBoxDetailsError),
        map(action => {
          this.toastService.generateErrorToast(action.error);
        })
      );
    },
    { dispatch: false }
  );

  openBox$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BoxesActions.openBox),
      exhaustMap(async action => {
        try {
          const box = await this.boxesService.openBox(action.tagB);
          const boxesByDates = await this.boxesService.synchroBoxes();

          return BoxesActions.openBoxSuccess({
            boxId: box.boxId,
            boxesByDates,
          });
        } catch (error) {
          return BoxesActions.openBoxError({ error });
        }
      })
    );
  });

  openBoxSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BoxesActions.openBoxSuccess),
      exhaustMap(async action => {
        return BoxesActions.loadingBoxDetails({ boxId: action.boxId });
      })
    );
  });

  openBoxError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(BoxesActions.openBoxError),
        map(action => {
          this.toastService.generateErrorToast(action.error);
        })
      );
    },
    { dispatch: false }
  );

  reopenBox$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BoxesActions.reopenBox),
      exhaustMap(async action => {
        try {
          await this.boxesService.reopenBox(action.box, true);

          return BoxesActions.loadingBoxes();
        } catch (error) {
          return BoxesActions.reopenBoxError({ error });
        }
      })
    );
  });

  reopenBoxError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(BoxesActions.reopenBoxError),
        map(action => {
          this.toastService.generateErrorToast(action.error);
        })
      );
    },
    { dispatch: false }
  );

  suspendBox$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BoxesActions.suspendBox),
      exhaustMap(async action => {
        await this.loadingService.presentLoading();

        try {
          await this.boxesService.suspendBox(action.box);

          return BoxesActions.loadingBoxes();
        } catch (error) {
          return BoxesActions.suspendBoxError({ error });
        } finally {
          await this.loadingService.dismissLoading();
        }
      })
    );
  });

  suspendBoxError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(BoxesActions.suspendBoxError),
        exhaustMap(async action =>
          this.toastService.generateErrorToast(action.error)
        )
      );
    },
    { dispatch: false }
  );

  loadingBoxDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BoxesActions.loadingBoxDetails, BoxesActions.openBoxSuccess),
      exhaustMap(async action => {
        // 2022-08-11: Remove the loading on parts refresh
        // await this.loadingService.presentLoading();
        try {
          const parts = await this.boxesService.synchroBox(action.boxId);

          const boxDetails: BoxDetails = {
            boxId: action.boxId,
            parts,
          };

          return BoxesActions.loadingBoxDetailsSuccess({ boxDetails });
        } catch (error) {
          return BoxesActions.loadingBoxDetailsError({ error });
        } finally {
          // await this.loadingService.dismissLoading();
        }
      })
    );
  });

  loadingBoxDetailsSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(BoxesActions.loadingBoxDetailsSuccess),
        map(action =>
          this.router.navigate([`/box-details/${action.boxDetails.boxId}`])
        )
      );
    },
    { dispatch: false }
  );

  loadingBoxDetailsError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(BoxesActions.loadingBoxDetailsError),
        exhaustMap(async action =>
          this.toastService.generateErrorToast(action.error)
        )
      );
    },
    { dispatch: false }
  );

  boxPart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BoxesActions.boxPart),
      exhaustMap(async action => {
        await this.loadingService.presentLoading();

        var canBoxPart = true;
        var fiche = action.ficheBarcode;

        try {
          if (this.metadataService.checkFicheReadConfiguration()) {
            const check = await this.boxesService.checkFiche(
              action.ficheBarcode
            );

            if (check !== null) {
              await this.loadingService.dismissLoading();

              canBoxPart = await this.alertService.openBooleanAlert(
                this.translate.instant('boxes.duplicateDeclarationMessage', {
                  params: {
                    sequenceDate: this.pipe.transform(
                      check.sequenceDate,
                      'shortDate'
                    ),
                    sequenceType: check.sequenceType,
                    refNumber: check.refNumber,
                    supplier: check.supplier,
                    popId: check.popId,
                    pn: check.pn,
                    fiche: action.ficheBarcode,
                  },
                }),
                this.translate.instant('boxes.duplicateDeclarationHeader')
              );

              await this.loadingService.presentLoading();
            }
          } else {
            fiche = null;
          }

          if (canBoxPart)
            await this.boxesService.boxPart(action.part, action.box, fiche);

          return BoxesActions.loadingBoxDetails({ boxId: action.box.boxId });
        } catch (error) {
          return BoxesActions.boxPartError({ error });
        } finally {
          await this.loadingService.dismissLoading();
        }
      })
    );
  });

  boxPartError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(BoxesActions.boxPartError),
        exhaustMap(async action =>
          this.toastService.generateErrorToast(action.error)
        )
      );
    },
    { dispatch: false }
  );

  unboxPart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BoxesActions.unboxPart),
      exhaustMap(async action => {
        await this.loadingService.presentLoading();

        try {
          await this.boxesService.unboxPart(action.part, action.box);

          return BoxesActions.loadingBoxDetails({ boxId: action.box.boxId });
        } catch (error) {
          return BoxesActions.boxPartError({ error });
        } finally {
          await this.loadingService.dismissLoading();
        }
      })
    );
  });

  unboxPartError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(BoxesActions.unboxPartError),
        exhaustMap(async action =>
          this.toastService.generateErrorToast(action.error)
        )
      );
    },
    { dispatch: false }
  );

  closeBox$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BoxesActions.closeBox),
      exhaustMap(async action => {
        await this.loadingService.presentLoading();

        try {
          const boxCloseResponseDto = await this.boxesService.closeBox(
            action.box
          );

          if (boxCloseResponseDto.fileWarning) {
            this.alertService.generateErrorAlert({
              error: {
                code: 150,
                message: this.translate.instant('errors.flatFileWarning'),
              },
            });
          }

          return BoxesActions.closeBoxSuccess();
        } catch (error) {
          return BoxesActions.closeBoxError({ error });
        } finally {
          await this.loadingService.dismissLoading();
        }
      })
    );
  });

  closeBoxSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BoxesActions.closeBoxSuccess),
      map(() => {
        this.router.navigate(['/tabs/boxes']);
        return BoxesActions.loadingBoxes();
      })
    );
  });

  closeBoxError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(BoxesActions.closeBoxError),
        exhaustMap(async action =>
          this.toastService.generateErrorToast(action.error)
        )
      );
    },
    { dispatch: false }
  );
}
