/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, props } from '@ngrx/store';
import { Metadata } from './../../common/models/metadata.types';

export const loadingMetadata = createAction('[Metadata] Loading metadata');

export const loadingMetadataSuccess = createAction(
  '[Metadata] Metadata success',
  props<{ metadata: Metadata }>()
);

export const loadingMetadataError = createAction(
  '[Metadata] Metadata error',
  props<{ error: any }>()
);
