import { TagA } from '../../common/models/tags.types';

import { createAction, props } from '@ngrx/store';

import { Part } from 'src/app/common/models';

/** Loading quality */
export const loadingQuality = createAction('[Quality] Loading quality parts');

export const loadingQualitySuccess = createAction(
  '[Quality] Quality parts loading success',
  props<{ inspectedParts: TagA[] }>()
);

export const loadingQualityError = createAction(
  '[Quality] Quality parts loading error',
  props<{ error: any }>()
);

/** Tag part */
export const readTagToInspect = createAction(
  '[Quality] Inspect tag request',
  props<{ tagA: TagA }>()
);

export const readTagToInspectSuccess = createAction(
  '[Quality] Inspect tag success'
);

export const readTagToInspectError = createAction(
  '[Quality] Inspect tag error',
  props<{ tagA: TagA; error: any }>()
);

/** Cancel tag reading */
export const cancelTagReadingRequest = createAction(
  '[Quality] Cancel tag reading request',
  props<{ tagA: TagA }>()
);

export const cancelTagReadingSuccess = createAction(
  '[Quality] Cancel tag reading success'
);

export const cancelTagReadingError = createAction(
  '[Quality] Cancel tag reading error',
  props<{ error: any }>()
);
