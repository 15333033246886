import { TagsService } from '../../services/tags/tags.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map } from 'rxjs/operators';

import * as QualityActions from './quality.actions';

import {
  LoadingService,
  QualityService,
  ToastService,
  AlertService,
} from '../../services';

import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';

@Injectable()
export class QualityEffects {
  constructor(
    private actions$: Actions,
    private loadingService: LoadingService,
    private qualityService: QualityService,
    private translate: TranslateService,
    private toastService: ToastService,
    private alertService: AlertService,
    private store: Store
  ) {}

  loadingQuality$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QualityActions.loadingQuality),
      exhaustMap(async () => {
        try {
          const inspectedParts = await this.qualityService.qualityAvailable();

          inspectedParts.map(taggedPart => {
            if (taggedPart.timestamp) taggedPart.timestamp += 'Z';
          });

          return QualityActions.loadingQualitySuccess({ inspectedParts });
        } catch (error) {
          return QualityActions.loadingQualityError({ error });
        }
      })
    );
  });

  loadingQualityError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(QualityActions.loadingQualityError),
        map(action => {
          this.toastService.generateErrorToast(action.error);
        })
      );
    },
    { dispatch: false }
  );

  readTagToInspect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QualityActions.readTagToInspect),
      exhaustMap(async ({ tagA }) => {
        await this.loadingService.presentLoading();

        try {
          await this.qualityService.qualityRegister(tagA);

          return QualityActions.readTagToInspectSuccess();
        } catch (error) {
          return QualityActions.readTagToInspectError({ tagA, error });
        } finally {
          await this.loadingService.dismissLoading();
        }
      })
    );
  });

  readTagToInspectSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QualityActions.readTagToInspectSuccess),
      map(() => {
        this.toastService.success(
          this.translate.instant('quality.readTagToInspectSuccess')
        );
        return QualityActions.loadingQuality();
      })
    );
  });

  readTagToInspectError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(QualityActions.readTagToInspectError),
        map(({ tagA, error }) => {
          if (this.isPartAlreadyRegisteredError(error)) {
            const cancelButton = [
              {
                text: this.translate.instant('quality.cancelTagReading'),
                cssClass: 'cancel-tag-reading-button',
                handler: () =>
                  // eslint-disable-next-line @ngrx/no-dispatch-in-effects
                  this.store.dispatch(
                    QualityActions.cancelTagReadingRequest({ tagA })
                  ),
              },
            ];

            this.alertService.generateErrorAlert(error, cancelButton);
          } else {
            this.alertService.generateErrorAlert(error);
          }
        })
      );
    },
    { dispatch: false }
  );

  isPartAlreadyRegisteredError(error: any): boolean {
    return (
      error.hasOwnProperty('error') &&
      error.error.hasOwnProperty('code') &&
      error.error.code === 3009
    );
  }

  cancelTagReadingRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QualityActions.cancelTagReadingRequest),
      exhaustMap(async ({ tagA }) => {
        await this.loadingService.presentLoading();

        try {
          await this.qualityService.qualityCancel(tagA);

          return QualityActions.cancelTagReadingSuccess();
        } catch (error) {
          return QualityActions.cancelTagReadingError({ error });
        } finally {
          await this.loadingService.dismissLoading();
        }
      })
    );
  });

  cancelTagReadingSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QualityActions.cancelTagReadingSuccess),
      map(() => {
        this.toastService.success(
          this.translate.instant('quality.cancelTagReadingSuccess')
        );
        return QualityActions.loadingQuality();
      })
    );
  });

  cancelTagReadingError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(QualityActions.cancelTagReadingError),
        map(({ error }) => {
          this.toastService.generateErrorToast(error);
        })
      );
    },
    { dispatch: false }
  );
}
