import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map } from 'rxjs/operators';

import * as ExpeditionsActions from './expeditions.actions';

import {
  LoadingService,
  ExpeditionsService,
  ToastService,
} from '../../services';
import { ExpeditionDetails } from 'src/app/common/models';
import { Router } from '@angular/router';

@Injectable()
export class ExpeditionsEffects {
  constructor(
    private actions$: Actions,
    private loadingService: LoadingService,
    private expeditionsService: ExpeditionsService,
    private toastService: ToastService,
    private router: Router
  ) {}

  loadingExpeditions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExpeditionsActions.loadingExpeditions),
      exhaustMap(async () => {
        try {
          let expeditions = await this.expeditionsService.getExpeditions();

          expeditions.map(expedition => {
            expedition.dateTime += 'Z';
          });

          return ExpeditionsActions.loadingExpeditionsSuccess({ expeditions });
        } catch (error) {
          return ExpeditionsActions.loadingExpeditionsError({ error });
        }
      })
    );
  });

  loadingExpeditionsError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ExpeditionsActions.loadingExpeditionsError),
        map(action => this.toastService.generateErrorToast(action.error))
      );
    },
    { dispatch: false }
  );

  loadingExpeditionDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExpeditionsActions.loadingExpeditionDetails),
      exhaustMap(async action => {
        await this.loadingService.presentLoading();

        try {
          const boxes = await this.expeditionsService.getExpeditionBoxes(
            action.expeditionId
          );

          const expeditionDetails: ExpeditionDetails = {
            expeditionId: action.expeditionId,
            expeditionBoxes: boxes,
          };

          return ExpeditionsActions.loadingExpeditionDetailsSuccess({
            expeditionDetails,
          });
        } catch (error) {
          return ExpeditionsActions.loadingExpeditionDetailsError({ error });
        } finally {
          await this.loadingService.dismissLoading();
        }
      })
    );
  });

  loadingExpeditionDetailsSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ExpeditionsActions.loadingExpeditionDetailsSuccess),
        map(action =>
          this.router.navigate([
            `/expedition-details/${action.expeditionDetails.expeditionId}`,
          ])
        )
      );
    },
    { dispatch: false }
  );

  loadingExpeditionDetailsError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ExpeditionsActions.loadingExpeditionDetailsError),
        map(action => this.toastService.generateErrorToast(action.error))
      );
    },
    { dispatch: false }
  );

  dispatchBox$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExpeditionsActions.dispatchBox),
      exhaustMap(async action => {
        try {
          await this.expeditionsService.dispatchBox(
            action.box,
            action.expedition
          );

          return ExpeditionsActions.loadingExpeditionDetails({
            expeditionId: action.expedition.id,
          });
        } catch (error) {
          return ExpeditionsActions.dispatchBoxError({ error });
        }
      })
    );
  });

  dispatchBoxError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ExpeditionsActions.dispatchBoxError),
        map(action => this.toastService.generateErrorToast(action.error))
      );
    },
    { dispatch: false }
  );

  removeBox$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExpeditionsActions.removeBox),
      exhaustMap(async action => {
        try {
          await this.expeditionsService.removeBox(
            action.box,
            action.expedition
          );

          return ExpeditionsActions.loadingExpeditionDetails({
            expeditionId: action.expedition.id,
          });
        } catch (error) {
          return ExpeditionsActions.removeBoxError({ error });
        }
      })
    );
  });

  removeBoxError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ExpeditionsActions.removeBoxError),
        map(action => this.toastService.generateErrorToast(action.error))
      );
    },
    { dispatch: false }
  );

  endExpedition$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExpeditionsActions.endExpedition),
      exhaustMap(async action => {
        try {
          await this.expeditionsService.endExpedition(action.expedition);

          return ExpeditionsActions.endExpeditionSuccess();
        } catch (error) {
          return ExpeditionsActions.dispatchBoxError({ error });
        }
      })
    );
  });

  endExpeditionSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExpeditionsActions.endExpeditionSuccess),
      map(() => {
        this.router.navigate(['/tabs/expeditions']);
        return ExpeditionsActions.loadingExpeditions();
      })
    );
  });

  endExpeditionError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ExpeditionsActions.endExpeditionError),
        map(action => this.toastService.generateErrorToast(action.error))
      );
    },
    { dispatch: false }
  );

  abortExpedition$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExpeditionsActions.abortExpedition),
      exhaustMap(async action => {
        try {
          await this.expeditionsService.abortExpedition(action.expedition);

          return ExpeditionsActions.abortExpeditionSuccess();
        } catch (error) {
          return ExpeditionsActions.dispatchBoxError({ error });
        }
      })
    );
  });

  abortExpeditionSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExpeditionsActions.abortExpeditionSuccess),
      map(() => {
        this.router.navigate(['/tabs/expeditions']);
        return ExpeditionsActions.loadingExpeditions();
      })
    );
  });

  abortExpeditionError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ExpeditionsActions.abortExpeditionError),
        map(action => this.toastService.generateErrorToast(action.error))
      );
    },
    { dispatch: false }
  );
}
