import { TranslateService } from '@ngx-translate/core';
import { MetadataService } from './../metadata/metadata.service';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  /** Must be in milliseconds. */
  public duration: number;

  constructor(
    private toastController: ToastController,
    private metadataService: MetadataService,
    private translate: TranslateService
  ) {
    this.duration = 5000;
  }

  toast(message: string, duration: number = this.duration): void {
    this.toastController
      .create({
        message,
        duration,
        cssClass: 'toast-custom-position',
      })
      .then(t => t.present());
  }

  success(message: string, duration: number = this.duration): void {
    this.toastController
      .create({
        message,
        duration,
        color: 'success',
        position: 'top',
        cssClass: 'toast-top-custom-position',
      })
      .then(t => t.present());
  }

  warning(message: string, duration: number = this.duration): void {
    this.toastController
      .create({
        message,
        duration,
        color: 'warning',
      })
      .then(t => t.present());
  }

  error(message: string, duration: number = this.duration): void {
    this.toastController
      .create({
        message,
        duration,
        color: 'danger',
      })
      .then(t => t.present());
  }

  primary(message: string, duration: number = this.duration): void {
    this.toastController
      .create({
        message,
        duration,
        color: 'primary',
      })
      .then(t => t.present());
  }

  generateErrorToast(error: any) {
    let message = this.translate.instant('errors.unhandledError');

    if (
      error.hasOwnProperty('error') &&
      error.error.hasOwnProperty('code') &&
      error.error.code &&
      this.metadataService.getErrorMetadata()[error.error.code]
    ) {
      message =
        (localStorage.getItem('lang') || navigator.language) === 'pt-BR'
          ? this.metadataService.getErrorMetadata()[error.error.code].messagePT
          : this.metadataService.getErrorMetadata()[error.error.code].message;
    } else if (
      error.hasOwnProperty('error') &&
      error.error.hasOwnProperty('message') &&
      error.error.message
    ) {
      message = error.error.message;
    } else if (error.hasOwnProperty('message') && error.message) {
      message = error.message;
    }

    this.toast(message);
  }
}
