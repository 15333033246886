/* eslint-disable @typescript-eslint/no-explicit-any */
import { createReducer, on } from '@ngrx/store';
import * as ExpeditionsActions from './expeditions.actions';

import { Expedition, ExpeditionDetails } from 'src/app/common/models';

export interface IExpeditionsState {
  expeditions: Expedition[];
  expeditionDetails: ExpeditionDetails;
  error?: any;
}

export const initialState = {
  expeditions: [],
  expeditionDetails: null,
};

const _expeditionsReducer = createReducer(
  initialState,
  on(
    ExpeditionsActions.loadingExpeditionsSuccess,
    (state, { expeditions }): IExpeditionsState => {
      return {
        ...state,
        expeditions,
      };
    }
  ),
  on(
    ExpeditionsActions.loadingExpeditionsError,
    (state, { error }): IExpeditionsState => {
      return {
        ...state,
        expeditions: [],
        error,
      };
    }
  ),
  on(
    ExpeditionsActions.loadingExpeditionDetailsSuccess,
    (state, { expeditionDetails }): IExpeditionsState => {
      return {
        ...state,
        expeditionDetails,
      };
    }
  ),
  on(
    ExpeditionsActions.loadingExpeditionDetailsError,
    (state, { error }): IExpeditionsState => {
      return {
        ...state,
        expeditionDetails: null,
        error,
      };
    }
  )
);

export function expeditionsReducer(state, action) {
  return _expeditionsReducer(state, action);
}
