import { TagA } from 'src/app/common/models';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { Part } from './../../common/models/box.types';

const API_URL: string = environment.applicationApiUrl;

@Injectable({
  providedIn: 'root',
})
export class MarketService {
  constructor(private httpClient: HttpClient) {}

  synchroParts(status: number = 1): Promise<Part[]> {
    let params = new HttpParams();
    params = params.append('status', status);

    return this.httpClient
      .get<Part[]>(`${API_URL}/synchro/parts`, { params })
      .toPromise();
  }

  labelAState(tagA: TagA) {
    return this.httpClient
      .post(`${API_URL}/boxing/labelAState`, tagA)
      .toPromise();
  }

  tagPart(partNumber: string, popId: string, timestamp?: string) {
    const payload = {
      itemCode: partNumber,
      popId,
      timestamp,
    };

    return this.httpClient
      .patch(`${API_URL}/boxing/tagging`, payload)
      .toPromise();
  }

  untagPart(partNumber: string, popId: string) {
    const payload = {
      itemCode: partNumber,
      popId,
    };

    return this.httpClient
      .post(`${API_URL}/boxing/untagging`, payload)
      .toPromise();
  }
}
