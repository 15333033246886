import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import {
  LoadingController,
  ToastController,
  AlertController,
  AlertInput,
} from '@ionic/angular';

import { exhaustMap, map } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { LoadingService, AuthService } from './../../services';
import * as AuthActions from './auth.actions';
import { HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { loadingMetadata } from '../metadata';

@Injectable()
export class AuthEffects {
  loading = null;

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private toastController: ToastController,
    private loadingService: LoadingService,
    private alertController: AlertController,
    private translate: TranslateService,
    public router: Router
  ) {}

  loginRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loginRequest),
      exhaustMap(async action => {
        this.authService.logout();
        await this.loadingService.presentLoading();

        try {
          const loginSuccessResponse = await this.authService
            .login(action.credentials.login, action.credentials.password)
            .toPromise();
          return AuthActions.loginSuccess({ loginSuccessResponse });
        } catch (error) {
          return AuthActions.loginFailure({ error });
        } finally {
          this.loadingService.dismissLoading();
        }
      })
    );
  });

  loginSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loginSuccess),
      map(action => loadingMetadata())
    );
  });

  logoutRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.logoutRequest),
      exhaustMap(async () => {
        this.authService.logout();
        return AuthActions.logoutSuccess();
      })
    );
  });

  logoutSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.logoutSuccess),
        map(() => {
          this.router.navigate(['/login']);
        })
      );
    },
    { dispatch: false }
  );

  async presentToast(errorMessage: string) {
    const toast = await this.toastController.create({
      message: errorMessage,
      duration: 4000,
    });
    toast.present();
  }

  showErrorMessage = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.loginFailure),
        map(action => {
          action.error.status === 0
            ? this.presentToast(
                'Erro desconhecido, verifique sua conexão com a internet.'
              )
            : this.presentToast(
                action.error.error.message
                  ? action.error.error.message
                  : action.error.message
                  ? action.error.message
                  : action.error
              );
        })
      );
    },
    { dispatch: false }
  );

  saveToken = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.loginSuccess),
        map(result => {
          this.authService.saveAuthData(result.loginSuccessResponse);
        })
      );
    },
    { dispatch: false }
  );

  navigateToTabs = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.loginSuccess),
        map(action => {
          if (action.loginSuccessResponse.roles.includes('quality')) {
            this.router.navigate(['/quality']);
          } else {
            this.router.navigate(['/tabs']);
          }
        })
      );
    },
    { dispatch: false }
  );
}
