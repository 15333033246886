import { Box } from './../../common/models/box.types';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, props } from '@ngrx/store';
import { Expedition, ExpeditionDetails } from 'src/app/common/models';

/** Loading expeditions page */
export const loadingExpeditions = createAction(
  '[Expeditions] Loading expeditions'
);

export const loadingExpeditionsSuccess = createAction(
  '[Expeditions] Expeditions loading success',
  props<{ expeditions: Expedition[] }>()
);

export const loadingExpeditionsError = createAction(
  '[Expeditions] Expeditions loading error',
  props<{ error: any }>()
);

/** Loading expeditions details page */
export const loadingExpeditionDetails = createAction(
  '[Expeditions] Loading box details',
  props<{ expeditionId: number }>()
);

export const loadingExpeditionDetailsSuccess = createAction(
  '[Expeditions] Loading box details success',
  props<{ expeditionDetails: ExpeditionDetails }>()
);

export const loadingExpeditionDetailsError = createAction(
  '[Expeditions] Loading box details error',
  props<{ error: any }>()
);

/** Dispatch a box */
export const dispatchBox = createAction(
  '[Expeditions] Dispatch box request',
  props<{ box: Box; expedition: Expedition }>()
);

export const dispatchBoxSuccess = createAction(
  '[Expeditions] Dispatch box request success'
);

export const dispatchBoxError = createAction(
  '[Expeditions] Dispatch box request error',
  props<{ error: any }>()
);

/** Remove box */
export const removeBox = createAction(
  '[Expeditions] Remove box request',
  props<{ box: Box; expedition: Expedition }>()
);

export const removeBoxSuccess = createAction(
  '[Expeditions] Remove box request success'
);

export const removeBoxError = createAction(
  '[Expeditions] Remove box request error',
  props<{ error: any }>()
);

/** End expedition */
export const endExpedition = createAction(
  '[Expeditions] End expedition request',
  props<{ expedition: Expedition }>()
);

export const endExpeditionSuccess = createAction(
  '[Expeditions] End expedition success'
);

export const endExpeditionError = createAction(
  '[Expeditions] End expedition error',
  props<{ error: any }>()
);

/** abort expedition */
export const abortExpedition = createAction(
  '[Expeditions] Abort expedition request',
  props<{ expedition: Expedition }>()
);

export const abortExpeditionSuccess = createAction(
  '[Expeditions] Abort expedition success'
);

export const abortExpeditionError = createAction(
  '[Expeditions] Abort expedition error',
  props<{ error: any }>()
);
