/* eslint-disable @typescript-eslint/no-explicit-any */
import { createReducer, on } from '@ngrx/store';
import { Metadata } from 'src/app/common/models';
import * as MetadataActions from './metadata.actions';

export interface IMetadataState {
  metadata: Metadata;
  error?: any;
}

export const initialState = {
  metadata: null,
};

const _metadataReducer = createReducer(
  initialState,
  on(
    MetadataActions.loadingMetadataSuccess,
    (state, { metadata }): IMetadataState => {
      return {
        ...state,
        metadata,
      };
    }
  ),
  on(
    MetadataActions.loadingMetadataError,
    (state, { error }): IMetadataState => {
      return {
        ...state,
        metadata: null,
        error: error,
      };
    }
  )
);

export function metadataReducer(state, action) {
  return _metadataReducer(state, action);
}
