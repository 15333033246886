import { TagsService } from './../../services/tags/tags.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map } from 'rxjs/operators';

import * as MarketActions from './market.actions';

import { LoadingService, MarketService, ToastService } from '../../services';

@Injectable()
export class MarketEffects {
  constructor(
    private actions$: Actions,
    private loadingService: LoadingService,
    private marketService: MarketService,
    private toastService: ToastService,
    private tagsService: TagsService
  ) {}

  loadingMarket$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.loadingMarket),
      exhaustMap(async () => {
        try {
          const taggedParts = await this.marketService.synchroParts();

          taggedParts.map(taggedPart => {
            taggedPart.taggedAt += 'Z';
            taggedPart.readAt += 'Z';
          });

          return MarketActions.loadingMarketSuccess({ taggedParts });
        } catch (error) {
          return MarketActions.loadingMarketError({ error });
        }
      })
    );
  });

  loadingMarketError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MarketActions.loadingMarketError),
        map(action => {
          this.toastService.generateErrorToast(action.error);
        })
      );
    },
    { dispatch: false }
  );

  tagPart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.tagPart),
      exhaustMap(async action => {
        await this.loadingService.presentLoading();

        try {
          await this.marketService.tagPart(
            action.partNumber,
            action.popId,
            action.timestamp
          );

          return MarketActions.loadingMarket();
        } catch (error) {
          return MarketActions.tagPartError({ error });
        } finally {
          await this.loadingService.dismissLoading();
        }
      })
    );
  });

  tagPartError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MarketActions.tagPartError),
        map(action => {
          this.toastService.generateErrorToast(action.error);
        })
      );
    },
    { dispatch: false }
  );

  untagPart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.untagPart),
      exhaustMap(async action => {
        await this.loadingService.presentLoading();

        try {
          await this.marketService.untagPart(action.partNumber, action.popId);

          return MarketActions.loadingMarket();
        } catch (error) {
          return MarketActions.untagPartError({ error });
        } finally {
          await this.loadingService.dismissLoading();
        }
      })
    );
  });

  untagPartError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MarketActions.untagPartError),
        map(action => {
          this.toastService.generateErrorToast(action.error);
        })
      );
    },
    { dispatch: false }
  );

  compareTags$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.compareTags),
      exhaustMap(async action => {
        if (this.tagsService.compareTags(action.tagA, action.tagC)) {
          return MarketActions.tagPart({
            partNumber: action.tagC.partNumber,
            popId: action.tagC.popId,
            timestamp: action.tagA.timestamp,
          });
        } else {
          return MarketActions.compareTagsError({
            error: { error: { code: 121, message: 'Tags A e C diferem' } },
          });
        }
      })
    );
  });

  compareTagsError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MarketActions.compareTagsError),
        map(action => {
          this.toastService.generateErrorToast(action.error);
        })
      );
    },
    { dispatch: false }
  );
}
