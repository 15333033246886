/* eslint-disable @typescript-eslint/no-explicit-any */
import { createReducer, on } from '@ngrx/store';
import * as QualityActions from './quality.actions';

import { TagA } from 'src/app/common/models';

export interface IQualityState {
  inspectedParts: TagA[];
  error?: any;
}

export const initialState = {
  inspectedParts: [],
};

const _qualityReducer = createReducer(
  initialState,
  on(
    QualityActions.loadingQualitySuccess,
    (state, { inspectedParts }): IQualityState => {
      return {
        ...state,
        inspectedParts,
      };
    }
  ),
  on(QualityActions.loadingQualityError, (state, { error }): IQualityState => {
    return {
      ...state,
      inspectedParts: [],
      error,
    };
  })
);

export function qualityReducer(state, action) {
  return _qualityReducer(state, action);
}
