import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TagA } from 'src/app/common/models';

import { environment } from 'src/environments/environment';

const API_URL: string = environment.applicationApiUrl;

@Injectable({
  providedIn: 'root',
})
export class QualityService {
  constructor(private httpClient: HttpClient) {}

  qualityAvailable(): Promise<TagA[]> {
    return this.httpClient
      .get<TagA[]>(`${API_URL}/quality/available`)
      .toPromise();
  }

  qualityRegister(tagA: TagA) {
    return this.httpClient
      .post(`${API_URL}/quality/register`, tagA)
      .toPromise();
  }

  qualityCancel(tagA: TagA) {
    return this.httpClient
      .delete(`${API_URL}/quality/cancel`, { body: tagA })
      .toPromise();
  }
}
