import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IMetadataState } from './metadata.reducer';

export const selectMetadataState =
  createFeatureSelector<IMetadataState>('metadata');

export const selectMetadata = createSelector(
  selectMetadataState,
  (state: IMetadataState) => state
);
