import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthData, Credentials } from '../../common/models/auth.types';

const LOGIN_URL: string = environment.applicationApiUrl + '/login';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private httpClient: HttpClient,
    private jwtHelper: JwtHelperService
  ) {}

  login(login: string, password: string): Observable<AuthData> {
    const payload: Credentials = {
      login,
      password,
    };
    return this.httpClient.post<AuthData>(LOGIN_URL, payload);
  }

  /** Cleans the stored authentication credentials. */
  public logout(): void {
    localStorage.removeItem('authData');
  }

  public saveAuthData(authData: AuthData) {
    localStorage.setItem('authData', JSON.stringify(authData));
  }

  public loadAuthData(): AuthData {
    const authDataStr = localStorage.getItem('authData') || '{}';
    const authData = JSON.parse(authDataStr);
    return authData;
  }

  public getToken(): string {
    const authData = this.loadAuthData();
    return authData?.token || '';
  }

  public isLogged(): boolean {
    const authToken = this.getToken();

    // Check token expiration timeout.
    if (authToken && !this.jwtHelper.isTokenExpired(authToken)) {
      return true;
    }
    return false;
  }
}
