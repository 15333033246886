import { Metadata } from './../../common/models/metadata.types';
import { MetadataService, LoadingService } from '../../services';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map } from 'rxjs/operators';

import * as MetadataActions from './metadata.actions';

import { logoutRequest } from '../auth/auth.actions';

@Injectable()
export class MetadataEffects {
  constructor(
    private actions$: Actions,
    private metadataService: MetadataService,
    private loadingService: LoadingService
  ) {}

  loadingMetadata$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MetadataActions.loadingMetadata),
      exhaustMap(async () => {
        await this.loadingService.presentLoading();

        try {
          await this.metadataService.load();

          const metadata: Metadata = {
            boxMetadata: this.metadataService.getBoxMetadata(),
            partMetadata: this.metadataService.getPartMetadata(),
            errorMetadata: this.metadataService.getErrorMetadata(),
            colorMetadata: this.metadataService.getColorMetadata(),
            expeditionMetadata: this.metadataService.getExpeditionMetadata(),
            apiVersionMetadata: this.metadataService.getApiVersion(),
            configurationMetadata:
              this.metadataService.getConfigurationMetadata(),
          };

          return MetadataActions.loadingMetadataSuccess({ metadata });
        } catch (error) {
          return MetadataActions.loadingMetadataError({ error });
        } finally {
          this.loadingService.dismissLoading();
        }
      })
    );
  });

  loadingMetadataError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MetadataActions.loadingMetadataError),
      map(() => logoutRequest())
    );
  });
}
