/* eslint-disable @typescript-eslint/no-explicit-any */
import { createReducer, on } from '@ngrx/store';
import * as MarketActions from './market.actions';

import { Part } from 'src/app/common/models';

export interface IMarketState {
  taggedParts: Part[];
  error?: any;
}

export const initialState = {
  taggedParts: [],
};

const _marketReducer = createReducer(
  initialState,
  on(
    MarketActions.loadingMarketSuccess,
    (state, { taggedParts }): IMarketState => {
      return {
        ...state,
        taggedParts,
      };
    }
  ),
  on(MarketActions.loadingMarketError, (state, { error }): IMarketState => {
    return {
      ...state,
      taggedParts: [],
      error,
    };
  })
);

export function marketReducer(state, action) {
  return _marketReducer(state, action);
}
