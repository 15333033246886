import { TagA, TagC } from './../../common/models/tags.types';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, props } from '@ngrx/store';
import { Part } from 'src/app/common/models';

/** Loading market */
export const loadingMarket = createAction('[Market] Loading market parts');

export const loadingMarketSuccess = createAction(
  '[Market] Market parts loading success',
  props<{ taggedParts: Part[] }>()
);

export const loadingMarketError = createAction(
  '[Market] Market parts loading error',
  props<{ error: any }>()
);

/** Untag part */
export const untagPart = createAction(
  '[Market] Untag part request',
  props<{ partNumber: string; popId: string }>()
);

export const untagPartSuccess = createAction('[Market] Untag part success');

export const untagPartError = createAction(
  '[Market] Untag part error',
  props<{ error: any }>()
);

/** Tag part */
export const tagPart = createAction(
  '[Market] Tag part request',
  props<{ partNumber: string; popId: string; timestamp: string }>()
);

export const tagPartSuccess = createAction('[Market] Tag part success');

export const tagPartError = createAction(
  '[Market] Tag part error',
  props<{ error: any }>()
);

/** Compare tags */
export const compareTags = createAction(
  '[Market] Compare tags A and C',
  props<{ tagA: TagA; tagC: TagC }>()
);

export const compareTagsSuccess = createAction(
  '[Market] Compare tags A and C success'
);

export const compareTagsError = createAction(
  '[Market] Compare tags A and C error',
  props<{ error: any }>()
);
