import { Platform } from '@ionic/angular';

import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AuthService, ScannerService, AlertService } from './services';

import { Store } from '@ngrx/store';
import { AuthData } from './common/models';
import * as AuthActions from './states/auth/auth.actions';

import { selectAuth } from '../../src/app/states/auth';

import { AuthGuard } from './common/guards/auth/auth.guard';

import { environment } from './../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  version = environment.version;

  public appPages = [
    {
      title: 'production',
      url: '/tabs',
      icon: 'cube',
      access: ['operator'],
    },
    {
      title: 'quality',
      url: '/quality',
      icon: 'shield-checkmark',
      access: ['quality'],
    },
    { title: 'settings', url: '/settings', icon: 'settings', access: ['all'] },
  ];
  public logout = { title: 'logout', url: '/login', icon: 'log-out' };

  authState$ = this.store.select(selectAuth);

  constructor(
    private store: Store,
    private platform: Platform,
    private authService: AuthService,
    private translate: TranslateService,
    public scannerService: ScannerService,
    public alertService: AlertService,
    public authGuard: AuthGuard
  ) {
    translate.addLangs(['en-US', 'pt-BR']);
    translate.setDefaultLang('pt-BR');

    // Set app language based on browser language
    const browserLang = translate.getBrowserLang();
    const language = browserLang.match(/en-US|pt-BR/) ? browserLang : 'pt-BR';

    translate.use(localStorage.getItem('lang') || language);
    localStorage.setItem('lang', localStorage.getItem('lang') || language);

    document.body.setAttribute(
      'color-theme',
      localStorage.getItem('colorTheme') || 'light'
    );

    if (authService.isLogged()) {
      const authData: AuthData = authService.loadAuthData();
      this.store.dispatch(
        AuthActions.loginSuccess({ loginSuccessResponse: authData })
      );
    }

    this.platform.backButton.subscribeWithPriority(-1, () => {
      if (this.scannerService.isActive) {
        this.scannerService.stopScanner();
      }
    });
  }

  onLogout() {
    this.store.dispatch(AuthActions.logoutRequest());
  }
}
