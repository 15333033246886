import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { TagA, TagB, TagC } from './../../common/models';

export enum PartTypeConverters {
  B = 'bumper',
  D = 'deflector',
}

export enum SuppliersConverters {
  CB = 'CBU',
  KD = 'KD',
}

@Injectable({
  providedIn: 'root',
})
export class TagsService {
  constructor(private translate: TranslateService) {}

  /**
   * Given a C Tag, retrieves its data
   *
   * @param tag Given tag
   * @returns Tag data
   */
  decodeCTag(tag: string): TagC {
    try {
      const regEx = /([A-Z\-]+((?<=\-)\d)?)(\d+)POPID(\d{6})(\d{7})(\d+)/g;
      const decodedTag = regEx.exec(tag);

      const tagC: TagC = {
        colorCode: decodedTag[1],
        partNumber: decodedTag[3],
        popId: decodedTag[4],
        colorNumber: decodedTag[5],
        cuCode: decodedTag[6],
      };

      return tagC;
    } catch (error) {
      throw {
        code: 100,
        error: new Error(this.translate.instant('errors.invalidCTag')),
      };
    }
  }

  public decodeBTag(tag: string): TagB {
    try {
      const regEx =
        /([A-Z])\-([A-Z]{2,3})\-(\d+)\-(\d{4}[0-1][0-9]+[0-3][0-9])/g;
      const decodedTag = regEx.exec(tag);

      if (decodedTag.length < 5) {
        throw {
          code: 100,
          error: new Error(this.translate.instant('errors.invalidBTag')),
        };
      }

      const tagB: TagB = {
        sequenceType: PartTypeConverters[decodedTag[1]],
        supplier: decodedTag[2],
        refNumber: decodedTag[3],
        sequenceDate: decodedTag[4],
      };

      return tagB;
    } catch (error) {
      throw {
        code: 100,
        error: new Error(this.translate.instant('errors.invalidBTag')),
      };
    }
  }

  decodeATag(tag: string): TagA {
    try {
      const regEx = /(\d*)-(\d*)-?(.*)/g;
      const decodedTag = regEx.exec(tag);

      const tagA: TagA = {
        partNumber: decodedTag[1],
        colorNumber: decodedTag[2],
        timestamp: decodedTag[3] !== '' ? decodedTag[3] : null,
      };

      return tagA;
    } catch (error) {
      throw {
        code: 100,
        error: new Error(this.translate.instant('errors.invalidATag')),
      };
    }
  }

  compareTags(tagA: TagA, tagC: TagC): boolean {
    return (
      tagA.colorNumber === tagC.colorNumber &&
      tagA.partNumber === tagC.partNumber
    );
  }

  /**
   * Checks if a given tag is a C tag
   *
   * @param tag Given tag
   * @returns boolean
   */
  checkIfIsCTag(tag: string): boolean {
    const regEx = /([A-Z\-]+((?<=\-)\d)?)(\d+)POPID(\d{6})(\d{7})(\d+)/g;
    if (regEx.exec(tag)) return true;
    return false;
  }
}
