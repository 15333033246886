import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { registerLocaleData } from '@angular/common';
import localeEN from '@angular/common/locales/en';
import localePT from '@angular/common/locales/pt';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AuthEffects, authReducer } from './states/auth';
import { BoxesEffects, boxesReducer } from './states/boxes';
import { MarketEffects, marketReducer } from './states/market';
import { MetadataEffects, metadataReducer } from './states/metadata';
import { ExpeditionsEffects, expeditionsReducer } from './states/expeditions';
import { QualityEffects, qualityReducer } from './states/quality';

import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';

import { JwtModule } from '@auth0/angular-jwt';

export function tokenGetter() {
  const authDataStr = localStorage.getItem('authData') || '{}';
  const authData = JSON.parse(authDataStr);
  return authData?.token || '';
}

registerLocaleData(localePT);
registerLocaleData(localeEN);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    // Reducers
    StoreModule.forRoot({
      auth: authReducer,
      metadata: metadataReducer,
      boxes: boxesReducer,
      expeditions: expeditionsReducer,
      market: marketReducer,
      quality: qualityReducer,
    }),
    // Effects
    EffectsModule.forRoot([
      AuthEffects,
      MetadataEffects,
      BoxesEffects,
      ExpeditionsEffects,
      MarketEffects,
      QualityEffects,
    ]),
    // ngx-translate
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'pt-BR',
    }),
    // To use Redux Dev Tools
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: environment.allowedDomains,
        disallowedRoutes: environment.disallowedRoutes,
      },
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // Change this 'navigator.language' setting to force using another locale.
    { provide: LOCALE_ID, useValue: navigator.language },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
