/* eslint-disable @typescript-eslint/no-explicit-any */
import { createReducer, on } from '@ngrx/store';
import { BoxDetails, boxesByDate } from 'src/app/common/models';
import * as BoxesActions from './boxes.actions';

export interface IBoxesState {
  boxesByDates: boxesByDate[];
  boxDetails: BoxDetails;
  isLoading: boolean;
  error?: any;
}

export const initialState = {
  boxesByDates: [],
  isLoading: true,
  boxDetails: null,
};

const _boxesReducer = createReducer(
  initialState,
  on(BoxesActions.loadingBoxes, (state, {}): IBoxesState => {
    return {
      ...state,
      isLoading: true,
    };
  }),
  on(
    BoxesActions.loadingBoxesSuccess,
    (state, { boxesByDates }): IBoxesState => {
      return {
        ...state,
        boxesByDates,
        isLoading: false,
      };
    }
  ),
  on(BoxesActions.loadingBoxesError, (state, { error }): IBoxesState => {
    return {
      ...state,
      boxesByDates: [],
      isLoading: false,
      error,
    };
  }),
  on(BoxesActions.openBoxSuccess, (state, { boxesByDates }): IBoxesState => {
    return {
      ...state,
      boxesByDates,
      isLoading: false,
    };
  }),
  on(
    BoxesActions.loadingBoxDetailsSuccess,
    (state, { boxDetails }): IBoxesState => {
      return {
        ...state,
        boxDetails,
        isLoading: false,
      };
    }
  ),
  on(BoxesActions.loadingBoxDetailsError, (state, { error }): IBoxesState => {
    return {
      ...state,
      boxDetails: null,
      isLoading: false,
      error,
    };
  })
);

export function boxesReducer(state, action) {
  return _boxesReducer(state, action);
}
