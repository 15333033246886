import { Injectable } from '@angular/core';
import { AlertButton, AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { TagA } from 'src/app/common/models';
import { MetadataService } from '../metadata/metadata.service';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(
    public alertController: AlertController,
    private metadataService: MetadataService,
    private translate: TranslateService
  ) {}

  async openDefaultAlert(
    message: string,
    header: string,
    buttons?: (string | AlertButton)[],
    cssClass?: string | string[]
  ) {
    const alert = await this.alertController.create({
      header,
      message,
      buttons,
      cssClass,
    });
    await alert.present();
  }

  async openInputAlert(title: string, msg?: string): Promise<string> {
    return new Promise(async (resolve, _reject) => {
      const alert = await this.alertController.create({
        header: title,
        message: msg,
        cssClass: '',
        buttons: [
          {
            text: this.translate.instant('cancel'),
            handler: () => {},
          },
          {
            text: 'OK',
            handler: resp => {
              resolve(resp.code);
            },
          },
        ],
        inputs: [
          {
            name: 'code',
            placeholder: this.translate.instant('scanner.insertCode'),
          },
        ],
      });
      await alert.present();
    });
  }

  async openBooleanAlert(msg: string, title: string): Promise<boolean> {
    return new Promise(async (resolve, _reject) => {
      const alert = await this.alertController.create({
        header: title,
        message: msg,
        buttons: [
          {
            text: this.translate.instant('no'),
            handler: () => {
              resolve(false);
            },
          },
          {
            text: this.translate.instant('yes'),
            handler: () => {
              resolve(true);
            },
          },
        ],
      });
      await alert.present();
    });
  }

  generateErrorAlert(error: any, buttons?: (string | AlertButton)[]) {
    const header = this.translate.instant('warning');
    const message = this.generateErrorMessage(error);

    const alertButtons = buttons ? [...buttons, 'Ok'] : ['Ok'];

    this.openDefaultAlert(message, header, alertButtons);
  }

  generateErrorMessage(error: any) {
    let message = this.translate.instant('errors.unhandledError');

    if (
      error.hasOwnProperty('error') &&
      error.error.hasOwnProperty('code') &&
      error.error.code &&
      this.metadataService.getErrorMetadata()[error.error.code]
    ) {
      message =
        (localStorage.getItem('lang') || navigator.language) === 'pt-BR'
          ? this.metadataService.getErrorMetadata()[error.error.code].messagePT
          : this.metadataService.getErrorMetadata()[error.error.code].message;
    } else if (
      error.hasOwnProperty('error') &&
      error.error.hasOwnProperty('message') &&
      error.error.message
    ) {
      message = error.error.message;
    } else if (error.hasOwnProperty('message') && error.message) {
      message = error.message;
    }

    return message;
  }
}
