import { createAction, props } from '@ngrx/store';
import { AuthData, Credentials } from '../../common/models/auth.types';

export const loginRequest = createAction(
  '[Auth] Login Request',
  props<{ credentials: Credentials }>()
);

export const loginSuccess = createAction(
  '[Auth] Login Success',
  props<{ loginSuccessResponse: AuthData }>()
);

export const loginFailure = createAction(
  '[Auth] Login Failure',
  props<{ error: any }>()
);

export const selectSector = createAction(
  '[Auth] Select sector',
  props<{ loginSuccessResponse: AuthData }>()
);

export const logoutRequest = createAction('[Auth] Logout Request');

export const logoutSuccess = createAction('[Auth] Logout Success');
