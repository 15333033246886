import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IQualityState } from './quality.reducer';

export const selectQualityState =
  createFeatureSelector<IQualityState>('quality');

export const selectQuality = createSelector(
  selectQualityState,
  (state: IQualityState) => state
);
