import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  constructor(
    private loadingController: LoadingController,
    private translate: TranslateService
  ) {}

  async presentLoading() {
    const loading = await this.loadingController.create({
      cssClass: '',
      message: this.translate.instant('loading') + '...',
    });
    await loading.present();
  }

  dismissLoading() {
    this.loadingController.dismiss();
  }
}
