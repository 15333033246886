import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';

import {
  boxesByDate,
  TagB,
  Box,
  Part,
  PartResponseDto,
  CheckPartResponseDto,
  OpenBoxResponseDto,
  BoxCloseResponseDto,
} from 'src/app/common/models';

const API_URL: string = environment.applicationApiUrl;

@Injectable({
  providedIn: 'root',
})
export class BoxesService {
  pipe = new DatePipe(navigator.language);

  constructor(private httpClient: HttpClient) {}

  synchroBoxes(
    filter: string = '',
    status: number[] = [2, 3]
  ): Promise<boxesByDate[]> {
    let params = new HttpParams();
    status.map(status => (params = params.append('status', status)));

    if (filter !== '') params = params.append('filter', filter);

    return this.httpClient
      .get<boxesByDate[]>(`${API_URL}/synchro/boxes`, { params })
      .toPromise();
  }

  synchroBox(boxId: number): Promise<Part[]> {
    return this.httpClient
      .get<Part[]>(`${API_URL}/synchro/box/${boxId}`)
      .toPromise();
  }

  openBox(
    tagB: TagB,
    fIsReopening: boolean = false
  ): Promise<OpenBoxResponseDto> {
    const payload = {
      ...tagB,
      sequenceDate: new Date(
        `${tagB.sequenceDate.slice(0, 4)}-` +
          `${tagB.sequenceDate.slice(4, 6)}-` +
          `${tagB.sequenceDate.slice(6, 8)}`
      ).toISOString(),
      fIsReopening,
    };

    return this.httpClient
      .post<OpenBoxResponseDto>(`${API_URL}/boxing/open`, payload)
      .toPromise();
  }

  reopenBox(box: Box, fIsReopening: boolean = true): Promise<any> {
    const payload = {
      sequenceType: box.sequenceType,
      sequenceDate: box.sequenceDate,
      supplier: box.supplier,
      refNumber: box.refNumber,
      fIsReopening,
    };

    return this.httpClient.post(`${API_URL}/boxing/open`, payload).toPromise();
  }

  suspendBox(box: Box): Promise<any> {
    const payload = {
      sequenceType: box.sequenceType,
      sequenceDate: box.sequenceDate,
      supplier: box.supplier,
      refNumber: box.refNumber,
    };

    return this.httpClient
      .post(`${API_URL}/boxing/suspend`, payload)
      .toPromise();
  }

  checkFiche(fiche: string): Promise<CheckPartResponseDto | null> {
    return this.httpClient
      .get<CheckPartResponseDto | null>(
        `${API_URL}/boxing/fiche/check/${fiche}`
      )
      .toPromise();
  }

  boxPart(part: Part, box: Box, fiche?: string): Promise<PartResponseDto> {
    let payload = {
      itemCode: part.partNumber,
      popId: part.popId,
      boxIdentifier: {
        sequenceType: box.sequenceType,
        sequenceDate: box.sequenceDate,
        supplier: box.supplier,
        refNumber: box.refNumber,
      },
      fiche,
    };

    return this.httpClient
      .post<PartResponseDto>(`${API_URL}/boxing/part`, payload)
      .toPromise();
  }

  unboxPart(part: Part, box: Box): Promise<PartResponseDto> {
    const payload = {
      itemCode: part.partNumber,
      popId: part.popId,
      boxIdentifier: {
        sequenceType: box.sequenceType,
        sequenceDate: box.sequenceDate,
        supplier: box.supplier,
        refNumber: box.refNumber,
      },
    };

    return this.httpClient
      .post<PartResponseDto>(`${API_URL}/boxing/part/unbox`, payload)
      .toPromise();
  }

  closeBox(box: Box): Promise<BoxCloseResponseDto> {
    const payload = {
      sequenceType: box.sequenceType,
      sequenceDate: box.sequenceDate,
      supplier: box.supplier,
      refNumber: box.refNumber,
    };

    return this.httpClient
      .patch<BoxCloseResponseDto>(`${API_URL}/boxing/close`, payload)
      .toPromise();
  }
}
