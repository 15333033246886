import { createReducer, on } from '@ngrx/store';

import { loginFailure, loginSuccess } from './auth.actions';
import { AuthData } from '../../common/models/auth.types';

export interface IAuthState {
  authData: AuthData;
  loginError?: string;
}

export const initialState = {
  authData: null,
};

const _authReducer = createReducer(
  initialState,
  on(loginSuccess, (state, { loginSuccessResponse }): IAuthState => {
    return {
      ...state,
      authData: loginSuccessResponse,
    };
  }),
  on(loginFailure, (state, { error }): IAuthState => {
    return {
      ...state,
      authData: null,
      loginError: error,
    };
  })
);

export function authReducer(state, action) {
  return _authReducer(state, action);
}
