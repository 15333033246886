import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { Platform } from '@ionic/angular';
import { AlertService } from '../alert/alert.service';

@Injectable({
  providedIn: 'root',
})
export class ScannerService {
  isActive: boolean = false;
  isInsertingCodeManually: boolean = false;
  manuallyInsertCode: string = '';
  scanText: string = '';

  constructor(
    public platform: Platform,
    public alertService: AlertService,
    private translate: TranslateService
  ) {}

  setScanText(text: string): void {
    this.scanText = text;
  }

  getScanText(): string {
    return this.scanText;
  }

  // Recebe o input de acordo com a plataforma.
  async openScanner(scanText: string = '') {
    if (this.platform.is('hybrid')) {
      return await this.startScanner(scanText);
    } else {
      const message =
        scanText != ''
          ? scanText
          : this.translate.instant('scanner.insertCode');
      return await this.alertService.openInputAlert(message);
    }
  }

  async prepareScann() {
    BarcodeScanner.prepare();
  }

  async checkPermission() {
    return new Promise(async (resolve, reject) => {
      const status = await BarcodeScanner.checkPermission({
        force: true,
      });
      if (status.granted) {
        resolve(true);
      } else if (status.denied) {
        BarcodeScanner.openAppSettings();
        resolve(false);
      }
    });
  }

  stopScanner() {
    BarcodeScanner.stopScan();
    document.body.setAttribute(
      'color-theme',
      localStorage.getItem('colorTheme') || 'light'
    );
    this.isActive = false;
  }

  async insertCodeManually() {
    this.isInsertingCodeManually = true;

    this.manuallyInsertCode = await this.alertService.openInputAlert(
      this.translate.instant('scanner.insertCode')
    );

    BarcodeScanner.stopScan({ resolveScan: true });
  }

  async startScanner(scanText: string = ''): Promise<string> {
    this.setScanText(scanText);
    const allowed = await this.checkPermission();
    let result = null;

    if (allowed) {
      this.isActive = true;
      BarcodeScanner.hideBackground();
      document.body.setAttribute('color-theme', 'light');
      result = await BarcodeScanner.startScan();

      if (result.hasContent) result = result.content;
      else if (this.isInsertingCodeManually) result = this.manuallyInsertCode;
    } else {
      alert(this.translate.instant('scanner.cameraNotAllowed'));
    }

    if (result === null) alert(this.translate.instant('scanner.noDataRead'));

    this.isInsertingCodeManually = false;
    this.isActive = false;
    this.manuallyInsertCode = '';
    this.stopScanner();

    return result;
  }

  ionViewWillLeave() {
    BarcodeScanner.stopScan();
    this.isActive = false;
  }
}
