import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IExpeditionsState } from './expeditions.reducer';

export const selectExpeditionsState =
  createFeatureSelector<IExpeditionsState>('expeditions');

export const selectExpeditions = createSelector(
  selectExpeditionsState,
  (state: IExpeditionsState) => state
);
